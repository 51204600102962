let data = ""

$.getJSON('../../data.json', json => {
    data = json.tools
})


const showModal = el => { 
    const modal = document.createElement('div')
    modal.id = 'modal'
    modal.addEventListener('click', closeModal)
    
    let output = ""
    
    data.forEach(d => { 
        
        if (d.id === el) output += `
            <div class="flex tools">
                <span class="close" onclick='closeModal()'>&times;</span>
                <div class="col-1">
                    <img
                        src="assets/img/tools/desktop/${d.image}"
                        alt="${d.name}"></div>
                <div class="col-1">
                    <h3>${d.name}</h3>
                    <strong>${d.title}</strong>
                    <p>${d.beschreibung}<br><br>
                    ${ d.url !== '' ? '<a class="button" href="'+ d.url +'" target="_blank">Testlink </a> (grundsätzliche Funktion, Beispiel-Layout)' : ''}
                    </p> 
                </div>
                <div class="col-1">
                    <a href="/bestellung?tool=${d.id}" class="main--button">Jetzt Tool anfragen</a>
                </div>
                `
            })
    output += "</div>"
    modal.innerHTML = output
    document.body.appendChild(modal)
}

const closeModal = () => { 
    document.body.removeChild(document.getElementById('modal'))
    // document.getElementById('leistungen').classList.remove('modal_open')
}

window.addEventListener('keyup', e => {
    if (document.getElementById('modal')) { 
        if( e.key === 'Escape' || e.key === 'Esc') closeModal()
    }

})


$(':required').one('blur keydown', function() {
    console.log('touched', this);
    $(this).addClass('touched');
  });